import React from 'react'

export default class GoogleTagManager extends React.Component {
  constructor(props) {
    super(props)

    this.convertToKeyValueString = this.convertToKeyValueString.bind(this)
    this.buildParts = this.buildParts.bind(this)
    this.GTMParts = this.GTMParts.bind(this)
  }

  convertToKeyValueString(obj) {
    return JSON.stringify(obj).slice(1, -1)
  }

  buildParts({
    id,
    dataLayerName = 'dataLayer',
    additionalEvents = {},
    scheme = '',
    previewVariables
  }) {
    if (id === undefined) {
      throw new Error('No GTM id provided')
    }

    const iframe = `
            <iframe src="${scheme}//www.googletagmanager.com/ns.html?id=${id}"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`

    const script = `
            (function(w,d,s,l,i){w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${this.convertToKeyValueString(
                  additionalEvents
                )}});
                var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;j.src='${scheme}//www.googletagmanager.com/gtm.js?id='+i+dl
                ${previewVariables ? `+"${previewVariables}"` : ''};
                f.parentNode.insertBefore(j,f);
            })(window,document,'script','${dataLayerName}','${id}');`

    return {
      iframe,
      script
    }
  }

  GTMParts(args) {
    const parts = this.buildParts(args)

    function noScriptAsReact() {
      return <noscript dangerouslySetInnerHTML={{ __html: parts.iframe }} />
    }

    function noScriptAsHTML() {
      return `<noscript>${parts.iframe}</noscript>`
    }

    function scriptAsReact() {
      return <script dangerouslySetInnerHTML={{ __html: parts.script }} />
    }

    function scriptAsHTML() {
      return `<script>${parts.script}</script>`
    }

    return {
      noScriptAsReact,
      noScriptAsHTML,
      scriptAsReact,
      scriptAsHTML
    }
  }

  componentDidMount() {
    const dataLayerName = this.props.dataLayerName || 'dataLayer'
    const scriptId = this.props.scriptId || 'vittude-gtm'

    if (!window[dataLayerName]) {
      const gtmScriptNode = document.getElementById(scriptId)
      eval(gtmScriptNode.textContent) /* eslint-disable-line */
    }
  }

  render() {
    const gtm = this.GTMParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false
    })

    return (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={this.props.scriptId || 'vittude-gtm'}>{gtm.scriptAsReact()}</div>
      </div>
    )
  }
}
