import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dynamic from 'next/dynamic'
import Script from 'next/script'

import Head from './shared/Head'

import { setUtmTracking } from '../utils/helpers'
import { setCoupon } from '../redux/modules/consultation'
import common from '../styles/common.scss'
import firasans from '../styles/firasans.scss'
import { NewRelicSnippet } from './shared/NewRelic'

import GoogleTagManager from 'components/shared/GoogleTagManager'
import { useUser } from 'providers/UserProvider'

const AppBanner = dynamic(() => import('./shared/AppBanner'), {
  ssr: false
})

const metaTags = {
  title: 'Encontre Seu Psicólogo e Agenda uma Consulta | Vittude',
  description:
    'Encontre psicólogo e agende uma consulta pelo site da Vittude. Aqui você encontra especialistas das mais variadas linhas terapêuticas em poucos cliques.',
  url: `${process.env.VITTUDE_OFFLINE_URL}`,
  image: '/static/images/vittude-symbol-v2.png'
}

const Wrapper = ({
  isGoogleTagManagerEnabled = true,
  isNewRelicEnabled = true,
  isWherebyEnabled = true,
  ...props
}) => {
  const { user, isUserLoggedIn } = useUser()

  useEffect(() => {
    if (!isUserLoggedIn) setUtmTracking()
    if (typeof window !== undefined && isUserLoggedIn) {
      dataLayer.push({
        userId: user?.id
      })
    }
  }, [])

  if (props.coupon) {
    props.setCoupon(props.coupon)
  }

  const styleHome = props?.home ? firasans : common

  return (
    <>
      <Head
        noIndex={props.noIndex}
        content={props.content}
        metaTags={props.metaTags || metaTags}
        {...props}
      >
        {props?.cssFile?.map((c, i) => (
          <style key={i} dangerouslySetInnerHTML={{ __html: c }} />
        ))}

        <style dangerouslySetInnerHTML={{ __html: styleHome }} />
      </Head>

      {isGoogleTagManagerEnabled && <GoogleTagManager gtmId={process.env.TAG_MANAGER_CODE} />}

      {isNewRelicEnabled && (
        <NewRelicSnippet
          licenseKey={process.env.NEWRELIC_LICENSEKEY_OFFLINE}
          applicationID={process.env.NEWRELIC_APPID_OFFLINE}
        />
      )}

      {isWherebyEnabled && (
        <Script
          type='module'
          src='https://cdn.srv.whereby.com/embed/v1.js'
          strategy='beforeInteractive'
        />
      )}

      <AppBanner />

      {props.children}
    </>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({ setCoupon }, dispatch)

export default connect(null, mapDispatchToProps)(Wrapper)
