import React, { Fragment } from 'react'

import Head from 'next/head'

const HeadComponent = props => {
  const { metaTags, noIndex, children } = props
  const url = props.url || `${process.env.VITTUDE_OFFLINE_URL}`

  return (
    <Head>
      <title>{metaTags.title}</title>
      <meta name='description' content={metaTags.description} />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
      />

      {/* <!-- Schema.org markup for Google+ --> */}
      <meta itemProp='name' content={metaTags.title} />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta itemProp='description' content={metaTags.description} />

      {metaTags.canonical && <link rel='canonical' href={metaTags.canonical} />}

      {(metaTags.canonical || metaTags.hreflang) && (
        <link rel='alternate' href={metaTags.canonical || metaTags.hreflang} hrefLang='pt-br' />
      )}

      {/* <!-- Open Graph data --> */}
      <meta property='og:title' content={metaTags.title} />
      <meta property='og:url' content={url} />
      <meta property='og:description' content={metaTags.description} />

      {/* <!-- Twitter Card data --> */}
      <meta name='twitter:title' content={metaTags.title} />
      <meta name='twitter:description' content={metaTags.description} />

      {noIndex ? (
        <Fragment>
          <meta name='robots' content='noindex, follow' />
          <meta name='googlebot' content='noindex, follow' />
        </Fragment>
      ) : process.env.API?.match(/dev/g) ? (
        <meta name='robots' content='noindex, nofollow' />
      ) : (
        <meta name='robots' content='index' />
      )}

      {children}
    </Head>
  )
}

export default HeadComponent
